<template>
  <div>
    <v-dialog transition="dialog-top-transition" v-model="show" max-width="800px" >

      <v-card
        class="mx-auto mt-3"
        max-width="800"
        elevation="3"
      >

        <v-card-title class="blue darken-2 white--text mb-4">
          <span>Inscripción Manual</span>
        </v-card-title>

        <v-card-text>

          <!-- Content -->
          <div v-if="!isLoading">

            <v-stepper v-model="stepValue" vertical>
              
              <!-- Personal data -->
              <v-stepper-step
                :complete="stepValue > 1"
                step="1"
              >
                <strong>Paso 1:</strong> Datos Personales
              </v-stepper-step>

              <v-stepper-content step="1">
                <v-form
                  ref="form"
                  v-model="validForm"
                  lazy-validation
                >
                  <v-row class="py-2">
                    <v-col cols="6">
                      <v-text-field
                        v-model="reg.studentInfo.rut"
                        label="RUT"
                        hint="Ingresar RUT. Guión y puntos son opcionales. Campo obligatorio"
                        persistent-hint
                        outlined dense
                        :rules="rutRules"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="reg.studentInfo.emailUCN"
                        label="Correo UCN"
                        hint="Ingrese el correo terminado en @ucn.cl . Campo obligatorio"
                        persistent-hint
                        outlined dense
                        :rules="emailRules"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="reg.studentInfo.fullname"
                        label="Nombre Completo"
                        hint="Ingrese el nombre completo. Campo obligatorio"
                        persistent-hint
                        outlined dense
                        :rules="nameRules"
                        required
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-select
                        v-model="reg.studentInfo.career"
                        :items="careers"
                        label="Carrera"
                        hint="Seleccione la carrera. Campo obligatorio"
                        persistent-hint
                        outlined dense
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="reg.studentInfo.phone"
                        label="Teléfono"
                        hint="Ingrese el número telefónico. Campo obligatorio"
                        persistent-hint
                        outlined dense
                        required
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="reg.studentInfo.address"
                        label="Dirección"
                        hint="Ingrese la dirección. Campo obligatorio"
                        persistent-hint
                        outlined dense
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="reg.studentInfo.lesion"
                        label="Lesiones (opcional)"
                        outlined dense
                      />
                    </v-col>
                  </v-row>
                </v-form>

                <v-btn
                  color="primary"
                  @click="goStep2()"
                  class="mt-3"
                  :disabled="!validForm"
                >
                  Continuar
                </v-btn>
              </v-stepper-content>

              <!-- Course selection -->
              <v-stepper-step
                :complete="stepValue > 2"
                step="2"
              >
                <strong>Paso 2:</strong> Seleccionar Talleres
              </v-stepper-step>

              <v-stepper-content step="2">

                <v-row class="py-2">
                  <v-col cols="12">
                    <v-select
                      v-model="reg.courses"
                      :items="registrationProcess.courses"
                      item-text="name"
                      item-value="_id"
                      label="Seleccione uno o más talleres"
                      multiple
                      chips
                      outlined
                    >
                      <v-icon slot="prepend">mdi-school</v-icon>
                    </v-select>
                  </v-col>
                </v-row>

                <div class="mt-5">
                  <v-btn @click="stepValue = 1" text>
                    Volver
                  </v-btn>
                  <v-btn
                    color="success"
                    @click="submitRegistration()"
                    :disabled="!validSelectedCourses"
                  >
                    Registrar
                  </v-btn>
                </div>
              </v-stepper-content>
            </v-stepper>
          </div>

          <div v-else>
            <LoadingIndicator />
          </div>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="show = false" >
            Cancelar
          </v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>

    <!-- snack notification -->
    <v-snackbar
      v-model="isSnackbar"
      :color="snackcolor"
      :timeout="2000"
      top
    >
      {{ snacktext }}
    </v-snackbar>

  </div>

</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import { getDDMMYYYY } from '@/libs/dateHelpers'
import { getCareers } from '@/libs/careers'
import Validation from '@/libs/validation'

export default {
  name: `ManualRegistrationDialog`,
  components: {
    LoadingIndicator,
  },
  props:{
    value: Boolean
  },
  computed: {
    show: {
      get() { return this.value },
      set(value) { this.$emit('input', value) }
    },
    validSelectedCourses() {
      return this.reg.courses.length > 0
    },
  },
  data: () => ({
    //state
    isLoading: true,
    isSaving: false,
    isSnackbar: false,
    snackcolor: 'success',
    snacktext: '',
    stepValue: 1,
    validForm: false,
    //validation
    nameRules: [ v => !!v || 'Este campo es requerido' ],
    rutRules: [
      v => Validation.verifyRUT(v) || 'El rut es inválido'
    ],
    emailRules: [
      v => !!v || 'Un e-mail es requerido',
      v => Validation.email(v) || 'El rut es inválido'
    ],
    //data
    registrationProcess: {
      _id: null,
      name: null,
      startDate: null,
      endDate: null,
      courses: [],
      totalRegs: 0,
    },
    reg: {
      studentInfo: {
        rut: null,
        fullname: null,
        emailUCN: null,
        career: null,
        phone: null,
        address: null,
        lesion: null,
      },
      courses: [],
    },
    careers: getCareers(),
  }),
  methods: {
    async loadLastRegistration() {
      try {
      
        this.isLoading = true
      
        const resp = await this.axios.get(`${process.env.VUE_APP_APIURL}/registration`)

        if(!resp.data.data) {
          this.isLoading = false
          return
        }

        this.registrationProcess = {
          name: resp.data.data.name,
          startDate: getDDMMYYYY(new Date(resp.data.data.startDate.replace('Z', ''))),
          endDate: getDDMMYYYY(new Date(resp.data.data.endDate.replace('Z', ''))),
          courses: resp.data.data.courses,
          totalRegs: resp.data.data.courses.reduce((acc, course) => acc + course.students.length, 0),
        }
      
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
    async submitRegistration() {
      try {

        this.isSaving = true

        await this.axios.post(`${process.env.VUE_APP_APIURL}/registration/adminsignup`, this.reg)

        this.snackcolor = 'success'
        this.snacktext = 'Se registró el alumno correctamente'
        this.isSnackbar = true

        //reset form
        this.stepValue = 1
        this.reg = {
          studentInfo: {
            rut: null,
            fullname: null,
            emailUCN: null,
            career: null,
            phone: null,
            address: null,
            lesion: null,
          },
          courses: [],
        },

        this.$emit('registration')
        this.show = false

      } catch (e) {
        console.error(e)
        this.snackcolor = 'error'
        this.snacktext = 'Se produjo un error al registrar el alumno'
        this.isSnackbar = true
      } finally {
        this.isSaving = false
      }
    },
    goStep2() {
      this.stepValue = (this.$refs.form.validate()) ? 2 : 1
    }
  },
  mounted() {
    this.loadLastRegistration()
  }
}
</script>

<style lang="scss" scoped>

.titleBck {

  color: #444;

  & h1 {
    font-size: 1.6rem;
  }

  & h2 {
    font-size: 1.2rem;
  }
}

.titleUnderline {
  background-color: #013A6B;
  background-image: -webkit-linear-gradient(30deg, rgb(243, 115, 53) 25%, #f5af19 10%);
  border-radius: 3px 3px 70px 3px;
}
</style>