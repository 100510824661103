<template>
  <v-btn @click="generateXLSX()" x-small color="primary" class="ml-2 mb-1">
    <v-icon small class="mr-1">mdi-google-spreadsheet</v-icon>
    Descargar Planilla
  </v-btn>
</template>

<script>
import { utils, writeFile } from 'xlsx'

export default {
  name: `ExportRegistrationToXLSX`,
  props: {
    data: {
      type: Array,
    }
  },
  methods: {
    generateXLSX() {
      if(this.data.length < 1) return

      let wb = utils.book_new()

      this.data.forEach(course => {

        const trimmedName = (course.name.length > 30) ? course.name.substring(0, 30) : course.name

        //change keys to custom names
        const courseNewHeader = []

        if(course.students.length < 1) {

          courseNewHeader.push({
            informacion: 'Este curso no tiene inscritos',
          })

        } else {

          course.students.forEach(student => {
            courseNewHeader.push({
              rut: student.rut,
              nombre: student.fullname,
              carrera: student.career,
              email: student.emailUCN,
              telefono: student.phone,
              direccion: student.address,
              lesion: student.lesion,
            })
          })

        }

        const courseWS = utils.json_to_sheet(courseNewHeader)
        utils.book_append_sheet(wb, courseWS, trimmedName)
        
      })

      writeFile(wb, 'inscripciones.xlsx')
    }
  },
}
</script>
