<template>
  <div>
    <v-dialog transition="dialog-top-transition" v-model="show" max-width="800px" >

      <v-card
        class="mx-auto mt-3"
        max-width="800"
        elevation="3"
      >

        <v-card-title class="blue darken-2 white--text mb-4">
          <span>Edición de inscripción de estudiante</span>
        </v-card-title>

        <v-card-text>

          <!-- Content -->
          <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
          >
            <v-row class="py-2">
              <v-col cols="6">
                <v-text-field
                  v-model="reg.studentInfo.rut"
                  label="RUT"
                  hint="Ingresar RUT. Guión y puntos son opcionales. Campo obligatorio"
                  persistent-hint
                  outlined dense
                  :rules="rutRules"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="reg.studentInfo.emailUCN"
                  label="Correo UCN"
                  hint="Ingrese el correo terminado en @ucn.cl . Campo obligatorio"
                  persistent-hint
                  outlined dense
                  :rules="emailRules"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="reg.studentInfo.fullname"
                  label="Nombre Completo"
                  hint="Ingrese el nombre completo. Campo obligatorio"
                  persistent-hint
                  outlined dense
                  :rules="nameRules"
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-select
                  v-model="reg.studentInfo.career"
                  :items="careers"
                  label="Carrera"
                  hint="Seleccione la carrera. Campo obligatorio"
                  persistent-hint
                  outlined dense
                  required
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="reg.studentInfo.phone"
                  label="Teléfono"
                  hint="Ingrese el número telefónico. Campo obligatorio"
                  persistent-hint
                  outlined dense
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="reg.studentInfo.address"
                  label="Dirección"
                  hint="Ingrese la dirección. Campo obligatorio"
                  persistent-hint
                  outlined dense
                  required
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="reg.studentInfo.lesion"
                  label="Lesiones (opcional)"
                  outlined dense
                />
              </v-col>
            </v-row>
          </v-form>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="show = false" >
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="editStudentRegistration()" :disabled="isSaving" :loading="isSaving">
            Guardar
          </v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>

    <!-- snack notification -->
    <v-snackbar
      v-model="isSnackbar"
      :color="snackcolor"
      :timeout="2000"
      top
    >
      {{ snacktext }}
    </v-snackbar>

  </div>

</template>

<script>
import { getCareers } from '@/libs/careers'
import Validation from '@/libs/validation'

export default {
  name: `EditStudentRegistrationDialog`,
  props:{
    value: Boolean,
    regProcess: {
      type: Object,
      required: true,
    }
  },
  computed: {
    show: {
      get() { return this.value },
      set(value) { this.$emit('input', value) }
    },
  },
  watch: {
    value(val) {
      if (val === true) {
        this.copyDataFromProp()
      }
    }
  },
  data: () => ({
    //state
    isSaving: false,
    isSnackbar: false,
    snackcolor: 'success',
    snacktext: '',
    validForm: false,
    //validation
    nameRules: [ v => !!v || 'Este campo es requerido' ],
    rutRules: [
      v => Validation.verifyRUT(v) || 'El rut es inválido'
    ],
    emailRules: [
      v => !!v || 'Un e-mail es requerido',
      v => Validation.email(v) || 'El rut es inválido'
    ],
    //data
    reg: {
      studentInfo: {
        rut: null,
        fullname: null,
        emailUCN: null,
        career: null,
        phone: null,
        address: null,
        lesion: null,
      },
    },
    careers: getCareers(),
  }),
  methods: {
    async copyDataFromProp() {
      Object.assign(this.reg.studentInfo, this.regProcess.studentData)
    },
    async editStudentRegistration() {
      try {
        this.isSaving = true

        await this.axios.put(`${process.env.VUE_APP_APIURL}/registration/adminsignup/${this.regProcess.eventId}/${this.regProcess.courseId}/${this.regProcess.studentId}`, this.reg.studentInfo)

        this.snackcolor = 'success'
        this.snacktext = 'Se editó la inscripción del alumno correctamente'
        this.isSnackbar = true

        //reset form
        this.reg = {
          studentInfo: {
            rut: null,
            fullname: null,
            emailUCN: null,
            career: null,
            phone: null,
            address: null,
            lesion: null,
          }
        },

        this.$emit('registration')
        this.show = false

      } catch (e) {
        console.error(e)
        this.snackcolor = 'error'
        this.snacktext = 'Se produjo un error al editar la inscripción del alumno'
        this.isSnackbar = true
      } finally {
        this.isSaving = false
      }
    },
  },
  mounted() {
    this.loadLastRegistration()
  }
}
</script>