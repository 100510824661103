<template>
  <v-container>

    <v-row>
      <v-col cols="12">

        <v-row class="mb-3">
          <v-col>
            <v-btn to="/admin/registrationstatus" color="secondary" small>
              <v-icon class="mr-2">mdi-arrow-left</v-icon>
              Volver
            </v-btn>
          </v-col>
        </v-row>

        <v-card>
          <v-card-title class="pb-0">

            <v-container>

              <v-row>
                <v-col class="mb-2 py-0">
                  <p class="text-h5 mb-0">
                    <v-avatar color="orange" size="38"><v-icon dark>mdi-draw</v-icon></v-avatar>
                    Inscripciones del último proceso
                  </p>
                </v-col>
              </v-row>

            </v-container>
            
          </v-card-title>

          <v-card-text>
            <v-container v-if="!isLoading">
              
              <!-- Main Process information -->
              <v-row>
                <v-col>
                  <p>
                    <v-icon class="mr-2">mdi-pen</v-icon>
                    <strong>Nombre proceso:</strong> {{registration.name}}
                  </p>

                  <p>
                    <v-icon class="mr-2">mdi-human-male-board</v-icon>
                    <strong>Número de Talleres:</strong> {{registration.courses.length}}
                  </p>
                </v-col>

                <v-col>
                  <p>
                    <v-icon class="mr-2">mdi-calendar</v-icon>
                    <strong>Fecha de Inicio:</strong> {{registration.startDate}}
                  </p>

                  <p>
                    <v-icon class="mr-2">mdi-calendar</v-icon>
                    <strong>Fecha de Término:</strong> {{registration.endDate}}
                  </p>
                </v-col>

              </v-row>

              <v-divider class="my-3"></v-divider>

              <!-- Registrations -->
              <v-row>
                <v-col>

                  <p class="text-h6" style="display: block;">
                    Lista de Inscripciones
                    <ExportRegistrationToXLSX :data="registration.courses" />
                    <v-btn @click="inManualRegistration = true" x-small color="success" class="ml-2 mb-1">
                      <v-icon small class="mr-1">mdi-plus</v-icon>
                      Inscripción manual
                    </v-btn>
                  </p>

                  <v-expansion-panels focusable popout>
                    <v-expansion-panel
                      v-for="(course,idx) in registration.courses"
                      :key="idx"
                    >
                      <v-expansion-panel-header>
                        <v-row>
                          <v-col>
                            <strong>{{course.name}}</strong>
                          </v-col>
                          <v-col>
                            {{course.students.length}} / {{course.slots}} Inscritos
                          </v-col>
                        </v-row>
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>

                        <div class="text-right">
                          <v-btn @click="copyListURL(course._id)" x-small color="secondary" class="mt-2 mb-1">
                            <v-icon small class="mr-1">mdi-link</v-icon>
                            Copiar link de lista compartida
                          </v-btn>
                        </div>

                        <v-data-table
                          :headers="headers"
                          :items="course.students"
                          dense
                          disable-pagination
                          hide-default-footer
                        >

                          <template v-slot:item.actions="{ item }">
                            <v-icon small class="mr-2" @click="openEditDialog(registration._id,course._id,item)">mdi-pencil</v-icon>
                            <v-icon small class="mr-2" @click="openDeleteDialog(registration._id,course._id,item._id)">mdi-close</v-icon>
                          </template>

                        </v-data-table>

                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                </v-col>
              </v-row>

            </v-container>

            <v-container v-else>
              <v-row>
                <v-col>
                  <LoadingIndicator />
                </v-col>
              </v-row>
            </v-container>
            
          </v-card-text>

        </v-card>

      </v-col>
    </v-row>

    <ManualRegistrationDialogVue v-model="inManualRegistration" @registration="loadLastRegistration()" />
    <EditStudentRegistrationDialog v-model="editDialog" :regProcess="editData" @registration="loadLastRegistration()" />
    <DeleteItemDialog v-model="deleteDialog" :deleteURL="deleteURL" @deleted="loadLastRegistration()" />

  </v-container>
</template>

<script>
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import ExportRegistrationToXLSX from '@/components/admin/ExportRegistrationToXLSX.vue'
import ManualRegistrationDialogVue from '@/components/admin/ManualRegistrationDialog.vue'
import EditStudentRegistrationDialog from '@/components/admin/EditStudentRegistrationDialog.vue'
import DeleteItemDialog from '@/components/admin/DeleteItemDialog.vue' 
import { getDDMMYYYY } from '@/libs/dateHelpers'

export default {
  name: `AdminViewRegistrations`,
  components: {
    LoadingIndicator,
    ExportRegistrationToXLSX,
    ManualRegistrationDialogVue,
    EditStudentRegistrationDialog,
    DeleteItemDialog
  },
  data: () => ({
    //state
    search: '',
    isLoading: true,
    isLoadingRegProcess: true,
    inToggleDialog: false,
    inManualRegistration: false,
    editDialog: false,
    editData: {
      eventId: null,
      courseId: null,
      studentId: null,
      studentData: {
        rut: null,
        fullname: null,
        emailUCN: null,
        career: null,
        phone: null,
        address: null,
        lesion: null,
      }
    },
    deleteDialog: false,
    deleteURL: '',
    //data
    inRegistrationProcess: false,
    registration: {
      _id: null,
      name: null,
      startDate: null,
      endDate: null,
      courses: [],
      totalRegs: 0,
    },
    headers: [
      { text: 'Rut', value: 'rut' },
      { text: 'Nombre Alumno', value: 'fullname' },
      { text: 'Carrera', value: 'career' },
      { text: 'Acciones', value: 'actions' },
    ],
  }),
  methods: {
    async loadRegistration() {
      Promise.all([
        this.loadLastRegistration(),
        this.loadInRegistration(),
      ])
    },
    async loadLastRegistration() {
      try {
      
        this.isLoading = true
      
        const resp = await this.axios.get(`${process.env.VUE_APP_APIURL}/registration`)

        if(!resp.data.data) {
          this.isLoading = false
          return
        }

        this.registration = {
          _id: resp.data.data._id,
          name: resp.data.data.name,
          startDate: getDDMMYYYY(new Date(resp.data.data.startDate.replace('Z', ''))),
          endDate: getDDMMYYYY(new Date(resp.data.data.endDate.replace('Z', ''))),
          courses: resp.data.data.courses,
          totalRegs: resp.data.data.courses.reduce((acc, course) => acc + course.students.length, 0),
        }
      
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
    async loadInRegistration() {
      try {

        this.isLoadingRegProcess = true

        const response = await this.axios.get(`${process.env.VUE_APP_APIURL}/settings`)
        this.inRegistrationProcess = response.data.data.inRegistrationProcess || false

      } catch (e) {
        console.log(e)
      } finally {
        this.isLoadingRegProcess = false
      }
    },
    async copyListURL(URL) {
      try {
        await navigator.clipboard.writeText(`https://galpon-cultural.ucn.cl/#/verinscripcion/${URL}`)
        alert('Link copiado en el portapapeles')
      } catch (e) {
        console.error('Coulnt copy URL to clipboard')
      }
    },
    openEditDialog(eventId, courseId, studentData) {
      this.editData.eventId = eventId
      this.editData.courseId = courseId
      this.editData.studentId = studentData._id
      this.editData.studentData = studentData
      this.editDialog = true
    },
    openDeleteDialog(eventId, courseId, studentId) {
      this.deleteURL = `${process.env.VUE_APP_APIURL}/registration/id/${eventId}/${courseId}/${studentId}`
      this.deleteDialog = true
    },
  },
  mounted() {
    this.loadRegistration()
  },
}
</script>