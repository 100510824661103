import { render, staticRenderFns } from "./ManualRegistrationDialog.vue?vue&type=template&id=11a51ddc&scoped=true&"
import script from "./ManualRegistrationDialog.vue?vue&type=script&lang=js&"
export * from "./ManualRegistrationDialog.vue?vue&type=script&lang=js&"
import style0 from "./ManualRegistrationDialog.vue?vue&type=style&index=0&id=11a51ddc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11a51ddc",
  null
  
)

export default component.exports